import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Cancel, Google, HidePass, ShowPass } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import ErrorMessage from '../../../../Errors'
import { SIGN_UP, SIGN_UP_GOOGLE } from '../../../../graphql/mutations/account'
import { useGoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jsonwebtoken';
// import { JwtPayload, jwtDecode } from "jwt-decode";
import Button from '../button'
import { Input } from '../inputs'
import { Container, ErrorLine, FormGroup, FormGroupCont, FormItem, FormOpt, GoogleBtnCont, TagLine } from './styles'
import { ButtonItem, GoogleBtn } from '../button/styles'

const Form = () => {
    const initCreds: any = {
        email: '',
        password: '',
        msgToken: localStorage.getItem('FCM_TOKEN')
    }
    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')
    const [type, setType] = useState('password')
    const [creds, setCreds] = useState(initCreds);
    const navigate = useNavigate();

    const focus = (name: string) => {
        setFocused(name)
    }
    const switchType = () => {
        setType(type === 'password' ? 'text' : 'password')
    }

    const storeUserInfo = ({ token, account }: any) => {
        localStorage.setItem('token', token);
        localStorage.setItem('account', JSON.stringify(account))
    }

    const [signUp, { error, loading, data }] = useMutation(SIGN_UP, {
        onCompleted({ signUp }: any) {
            if (signUp) {
                storeUserInfo(signUp);
                navigate("/onboarding");
            }
            // const permisions: PermittedActions = JSON.parse(localStorage.getItem('perms') as any)
        }
    });

    const [singUpGoogle, { error:e, loading:signing, data:d }] = useMutation(SIGN_UP_GOOGLE, {
        onCompleted({ signUpGoogle }: any) {
            if (signUpGoogle) {
                storeUserInfo(signUpGoogle);
                navigate("/onboarding");
            } 
        }
    });
    
    if(e) console.log(e)

    if(signing) console.log('signing...')
    // if(d) console.log(`Data: ${JSON.stringify(d, null, 2)}`)

    if (error)
        console.log({ error })

    const handleClearInput = () => {
        setCreds({
            ...creds,
            phone: ''
        })
    }
    const handleChange = (e: any) => {
        e.persist();
        setCreds({
            ...creds,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log(creds)
        signUp({
            variables: {
                creds: { 
                    ...creds,
                }
            }
        })
    }

    const handleGoogleSignUp = (token: string) => {
        singUpGoogle({
            variables: {
               token,
                msgToken: localStorage.getItem('FCM_TOKEN')
            }
        })
    }

    const mouseEnter = (name: string) => {
        setCancel(name);
    }
    const mouseLeave = (name: string) => {
        setCancel(name);
    }


    // function handleGoogleLoginSuccess(tokenResponse) {

    //     const accessToken = tokenResponse.access_token;

    //     dispatch(signupGoogle(accessToken,nagivate))
    // }

    const login = useGoogleLogin({
        onSuccess: res => handleGoogleSignUp(res.access_token),
        // onSuccess: res => console.log(res),
        onError: error => console.log(error),
        // onSuccess: res => handleGoogleSignUp(res.access_token)
    });

    return (
        <Container>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e) }>
                <h6>Sign Up</h6>
                {/* <FormGroupCont>
                    <FormGroup
                        onMouseLeave={() => mouseLeave('name')}
                        onMouseEnter={() => mouseEnter('name')}
                    >
                        <Input
                            name='name'
                            label='Name'
                            value={creds.name}
                            focused={focused === 'name'}
                            placeholder='Business or personal name'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'name') &&
                            <Icon onClick={handleClearInput}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                </FormGroupCont> */}
                
                <FormGroupCont>
                    <FormGroup
                        top
                        onMouseLeave={() => mouseLeave('email')}
                        onMouseEnter={() => mouseEnter('email')}
                    >
                        <Input
                            name='email'
                            label='Email'
                            value={creds.email}
                            focused={focused === 'email'}
                            placeholder='user@example.com'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'email') &&
                            <Icon onClick={handleClearInput}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                    <FormGroup
                        onMouseLeave={() => mouseLeave('password')}
                        onMouseEnter={() => mouseEnter('password')}
                    >
                        <Input
                            type={type}
                            name='password'
                            label='Password'
                            value={creds.password}
                            focused={focused === 'password'}
                            placeholder=' Your account password'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        <Icon onClick={() => switchType()} id='pwd'> {
                            type === 'password' ? <HidePass /> : <ShowPass />
                        }
                        </Icon>
                    </FormGroup>
                </FormGroupCont>
                <Button 
                    loading={loading}
                    title="Create account" 
                    disabled={!creds.email || !creds.password} 
                />
                <ErrorLine>
                    {
                        error && <ErrorMessage error={error} />
                    }
                </ErrorLine>
            </FormItem>
            <div className='or'><p>OR</p></div>
            <GoogleBtnCont>
                <GoogleBtn onClick={() => login()}>
                    <Icon>
                        <Google />
                    </Icon>
                    Sign up with Google
                </GoogleBtn>
            </GoogleBtnCont>
            <TagLine>Already have account?
                <span>
                    <NavLink to='/signin' title='Home' >Sign in</NavLink>
                </span>
            </TagLine>
        </Container>
    )
}
export default Form