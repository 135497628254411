import React from 'react'
import { ReceiptCont, ReceiptTotal, ReceiptHeader } from './styles'
import Content from './content'
import { formatMoney, formatTime, format_date, genTransId, getCartTotal } from '../../../../utils'

const Receipt = React.forwardRef((props: any, ref: any) => (


    <ReceiptCont id='receipt' ref={ref}>
        <ReceiptHeader>
            <h6>{props.account?.info.name}</h6>
            <p className='address'>{props.account?.addresses?.length > 0 ? props.account?.addresses[0]?.street : '-'} Jalingo</p>
            <p className='phone'>{props.account?.phone}</p>
            <div id='sub--head'>
                <p id='date-id'>
                    {`Invoice: ${props.tid}`}
                    {/* {`Invoice: ${props.invoice?.tid?.slice(-5)||props.tid.toString().slice(-5)}`} */}
                    <span>
                        {format_date(props.invoice.createdAt || new Date())} {formatTime(props.invoice.createdAt || new Date())}
                    </span>
                </p>
                <p id='method'>
                    Payment Method:
                    <span>
                        {props.invoice.paymentMethod.toUpperCase()}
                    </span>
                </p>
            </div>
        </ReceiptHeader>
        <Content {...props}/>
        <ReceiptTotal id='total'>
            <p>
                <span>Total:</span>
                <span>{formatMoney(getCartTotal(props.invoice.stocks))}</span>
            </p>
            <p>
                <span>Change:</span>
                <span>{formatMoney(getCartTotal(props.invoice.stocks) - props.invoice.recieved > 0  ? props.invoice.recieved : getCartTotal(props.invoice.stocks))}.00</span>
            </p>
            -------------------------------------
        </ReceiptTotal>
        <div id='remarks' className='remark'>
            {/* <p>Attended By: Johnson Solomon</p> */}
            <p>{`${props.account?.username === 'moore' || props.account?.username === 'moorevet' ? 'God bless as you patronize us!!' : 'Thanks for your patronage'}`}</p>
            
            <p>Software by stosyst.com - <span>08068282257</span></p>
        </div>
    </ReceiptCont>
))


export default Receipt
